<template>
  <v-autocomplete
    :value="value"
    :items="items"
    v-bind="$attrs"
    @input="$emit('input', $event)"
  />
</template>

<script>
export default {
  name: 'AgAutocomplete',
  props: {
    value: {
      type: [Array, String],
      default: null,
    },
    itemList: {
      type: Array,
      default: () => ([]),
    },
    itemText: {
      type: String,
      default: '',
    },
  },
  computed: {
    items () {
      if (this.itemText) {
        return this.itemList.map(item => ({
          value: item._id,
          text: item[this.itemText],
        }))
      }
      return this.itemList
    },
  },
}
</script>
