<template>
  <div>
    <v-tooltip v-if="tooltip" top>
      <template v-slot:activator="{ on, attrs }">
        <div
          v-bind="attrs"
          v-on="on"
        >
          <v-switch
            :input-value="value"
            v-bind="$attrs"
            @change="$emit('input', $event)"
          />
        </div>
      </template>
      <span>{{ tooltip }}</span>
    </v-tooltip>
    <v-switch
      v-else
      :input-value="value"
      v-bind="$attrs"
      @change="$emit('input', $event)"
    />
  </div>
</template>

<script>
export default {
  name: 'AgSwitch',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: '',
    },
  },
}
</script>
