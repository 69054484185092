function getErrorMessage (errorKey) {
  return errorKey
}

function required (errorKey) {
  return value => !!value || value === 0 || getErrorMessage(errorKey)
}

function between (errorKey, { min, max }) {
  return value => !(value < min || value > max) || getErrorMessage(errorKey, { min, max })
}

function minLength (errorKey, { length }) {
  return value => value?.length >= length || getErrorMessage(errorKey, { length })
}

function maxLength (errorKey, { length }) {
  return value => value?.length <= length || getErrorMessage(errorKey, { length })
}

function noSpaces (errorKey) {
  return value => !(value?.includes(' ') ?? false) || getErrorMessage(errorKey)
}

function equal (errorKey, { compared }) {
  return value => value === compared || getErrorMessage(errorKey)
}

function except (errorKey, { list }) {
  return value => !list.includes(value) || getErrorMessage(errorKey)
}

function alpha (errorKey) {
  return value => /^[a-zA-Z]+$/.test(value) || getErrorMessage(errorKey)
}

function greater (errorKey, { number }) {
  return value => value >= number || getErrorMessage(errorKey, { number })
}

function lower (errorKey, { number }) {
  return value => value <= number || getErrorMessage(errorKey, { number })
}

function email (errorKey) {
  return value => !value || !!value.match(/\S+@\S+\.\S+/) || getErrorMessage(errorKey)
}

function integer (errorKey) {
  return value => value % 1 === 0 || getErrorMessage(errorKey)
}

export default {
  required,
  between,
  minLength,
  maxLength,
  noSpaces,
  equal,
  except,
  alpha,
  greater,
  lower,
  email,
  integer,
}
