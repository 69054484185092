<template>
  <div>
    <v-form v-model="mainFormValid" class="pa-4">
      <ag-autocomplete
        v-model="internalValue.partType"
        :rules="$options.rules.partType"
        label="Type de référence"
        :item-list="partTypeList"
        item-text="name"
        @input="change"
      />

      <ag-autocomplete
        v-model="internalValue.partNumber"
        :rules="$options.rules.partNumber"
        label="Référence"
        :item-list="filteredPartNumberList"
        item-text="name"
        @input="change"
      />

      <v-text-field
        v-model="internalValue.quantity"
        :rules="$options.rules.quantity"
        type="number"
        label="Quantité"
        @input="change"
      />

      <ag-switch
        v-if="canForceFirstPart"
        v-model="forceFirstSerialNumber"
        inset
        label="Forcer le premier numéro de série"
        @input="change"
      />

      <v-text-field
        v-if="firstPartInputActive"
        v-model="internalValue.firstPart"
        v-mask="'A######'"
        :rules="$options.rules.firstPart"
        placeholder="A000000"
        label="Premier numéro de série de l'OF"
        @input="change"
      />
    </v-form>
  </div>
</template>

<script>
import Repositories from 'src/repositories'
import Helpers from 'src/utils/helpers'
import FormRules from 'src/utils/form-rules'
import AgSwitch from 'src/components/common/AgSwitch'
import AgAutocomplete from 'src/components/common/AgAutocomplete'

export default {
  name: 'BatchForm',
  components: { AgSwitch, AgAutocomplete },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    internalValue: null,
    mainFormValid: false,
    partTypeList: [],
    partNumberList: [],
    forceFirstSerialNumber: false,
  }),
  computed: {
    isValidBatch () {
      return this.mainFormValid
    },
    filteredPartNumberList () {
      if (!this.internalValue.partType) return []
      return this.partNumberList.filter(
        partNumber => partNumber.partType._id === this.internalValue.partType,
      )
    },
    canForceFirstPart () {
      if (this.internalValue.partType === '5b4c8c911d33360c2526d87d' || this.selectedPartNumber?.forceDefinefirstSerialNumber) return false
      return true
    },
    firstPartInputActive () {
      if (this.selectedPartNumber?.forceDefinefirstSerialNumber) return true
      if (this.forceFirstSerialNumber) return true
      if (!this.canForceFirstPart) return false
      return false
    },
    selectedPartNumber () {
      if (!this.internalValue.partNumber) return null
      return this.partNumberList.find(
        partNumber => partNumber._id === this.internalValue.partNumber,
      )
    },
  },
  watch: {
    value: {
      immediate: true,
      handler (value) {
        this.internalValue = Helpers.clone(value)
        this.$emit('validate', this.isValidBatch)
      },
    },
    isValidBatch: {
      immediate: true,
      handler () {
        this.$emit('validate', this.isValidBatch)
      },
    },
  },
  async mounted () {
    const [
      partTypeList,
      partNumberList,
    ] = await Promise.all([
      Repositories.partType.getList(),
      Repositories.partNumber.getList({ archived: false }),
    ])
    this.partTypeList = partTypeList
    this.partNumberList = partNumberList
  },
  methods: {
    change () {
      this.$emit('input', this.internalValue)
      this.$emit('validate', this.isValidBatch)
    },
  },
  rules: {
    partType: [
      FormRules.required('requis'),
    ],
    partNumber: [
      FormRules.required('requis'),
    ],
    quantity: [
      FormRules.required('requis'),
      FormRules.greater('Veuilez saisir une valeur positive', { number: 0 }),
      FormRules.integer('Veuilez saisir une valeur entière'),
    ],
    firstPart: [
      FormRules.required('requis'),
      FormRules.minLength('Veuilez saisir un numéro de série valide', { length: 7 }),
      FormRules.maxLength('Veuilez saisir un numéro de série valide', { length: 7 }),
    ],
  },
}
</script>
